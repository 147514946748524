import { Button } from '@material-ui/core';
import React from 'react';

function UserBlock(props) {
  const accountEllipsis = props.account
    ? `${props.account.substring(0, 4)}...${props.account.substring(props.account.length - 4)}`
    : null;
  return (
    <div style={{display:"inline-block"}}>
      {props.account ? (
        <Button
          style={{ borderRadius: '16px', color:"#f3ba2f" }}
          onClick={() => {
            props.disconnectWallet();
          }}
        >
          <span style={{color:"#f3ba2f", fontSize:"18px", fontWeight:"bold"}}>{accountEllipsis}</span>
        </Button>
      ) : (
        <Button
          style={{ borderRadius: '5px', background: '#f3ba2f', color: '#000000', display:"inline-block", width:"150px"}}
          onClick={() => {
            props.connectWallet();
          }}
        >
          <span style={{color: '#000000', fontWeight:"bold"}}>Wallet</span>
        </Button>
      )}
    </div>
  );
}

export default UserBlock;
