import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  VAULT_FETCH_APYS_BEGIN,
  VAULT_FETCH_APYS_SUCCESS,
  VAULT_FETCH_APYS_FAILURE,
} from './constants';
import { getLatestApiCommitHash } from "../../web3/fetchLatestApiCommitHash"

export function fetchApys() {
  return dispatch => {
    dispatch({
      type: VAULT_FETCH_APYS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let API_URL ="https://api.privacyswap.finance";
      // API_URL ="http://localhost:4000";

      const BEEFY_URL ="https://api.beefy.finance";

      // const API_URL ="http://localhost:4000";

      const doRequest = axios.get(`${API_URL}/apy`);
      const doBeefyRequest = axios.get(`${BEEFY_URL}/apy`);

      console.log("fsdssdsd",doRequest)
      doRequest.then(
        res => {



          doBeefyRequest.then(
            res1 => {
              let data = {...res1.data,...res.data}


              dispatch({
                type: VAULT_FETCH_APYS_SUCCESS,
                data: data,
              });
              resolve({data});
            },
            err => {
              dispatch({
                type: VAULT_FETCH_APYS_FAILURE,
                data: { error: err },
              });
              reject(err);
              console.log("dsdsdsdsds",err)
            }
          );


          // dispatch({
          //   type: VAULT_FETCH_APYS_SUCCESS,
          //   data: res.data,
          // });
          // resolve(res);
          // console.log("dsdsds",res.data)
        },
        err => {
          dispatch({
            type: VAULT_FETCH_APYS_FAILURE,
            data: { error: err },
          });
          reject(err);
          console.log("dsdsdsdsds",err)
        }
      );
    });

    return promise;
  };
}

export function useFetchApys() {
  const dispatch = useDispatch();

  const { apys, fetchApysPending, fetchApysDone } = useSelector(
    state => ({
      apys: state.vault.apys,
      fetchApysDone: state.vault.fetchApysDone,
      fetchApysPending: state.vault.fetchApysPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(() => {
    dispatch(fetchApys());
  }, [dispatch]);

  return {
    apys,
    fetchApys: boundAction,
    fetchApysDone,
    fetchApysPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VAULT_FETCH_APYS_BEGIN:
      return {
        ...state,
        fetchApysPending: true,
      };

    case VAULT_FETCH_APYS_SUCCESS:
      return {
        ...state,
        apys: action.data,
        fetchApysDone: true,
        fetchApysPending: false,
      };

    case VAULT_FETCH_APYS_FAILURE:
      return {
        ...state,
        fetchApysPending: false,
      };

    default:
      return state;
  }
}
