import Panel from 'components/Panel';
import UserBlock from 'components/UserBlock';
import React, { useEffect, useState } from 'react';
import { Icon } from 'react-icons-kit';
import { bars } from 'react-icons-kit/fa/bars';
import './menu.css';
import image from '../../assets/img/logo.png';

function Menu(props) {
  const [closed, setclosed] = useState(false);

  useEffect(()=>{
    if(window.screen.width < 900   ){
      setclosed(true)
    }
  },[])
  return (
    <div className="navBar" style={{ boxShadow: '0 0 10px 2px gray', padding: '10px' }}>
      <div style={{ paddingLeft: "15px" }}>
        <img onClick={() => setclosed(!closed)} src="https://app.privacyswap.finance/images/menuicon.png" width="23px" style={{ position: "absolute", left: "10px", top: "15px", cursor: "pointer" }} />
        {/* <Icon
          onClick={() => setclosed(!closed)}
          icon={bars}
          size={23}
          style={{ color: '#FFFFFF', position:"absolute", left:"10px",top:"15px", cursor:"pointer" }}
        /> */}
        <img src={window.screen.width > 900 ? image : "https://app.privacyswap.finance/images/egg/logo.png"} style={{ width: `${window.screen.width> 900 ?"200px" : "35px"}`, marginLeft: "20px" }} />
      </div>
      <span style={{ listStyle: 'none', display: 'inline', float: 'right', clear: 'both' }}>
        <UserBlock
          account={props.address}
          connected={props.connected}
          connectWallet={props.connectWallet}
          disconnectWallet={props.disconnectWallet}
        />
      </span>

      <Panel closed={closed} setclosed={() => setclosed(false)} />
    </div>
  );
}

export default Menu;
