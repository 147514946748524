import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const useStyles = makeStyles(styles);

const Disclaimer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container item className={classes.root} justify="center" style={{border:"2px solid #f3ba2f", marginBottom:"10px"}}>
      <Typography className={classes.disclaimer} style={{textAlign:"center"}}>
        Vaults are governed by smart contracts and the underlying platform. PrivacySwap does not guarantee the security of assets as they are held on the underlying platforms' smart contracts. That being said, PrivacySwap conducts due diligence on underlying platforms on a best effort basis and are not to be held responsible for any loss resulting from depositing assets into vaults.Assets can decrease in value due to market conditions.
      </Typography>
    </Grid>
  );
};

export default memo(Disclaimer);
