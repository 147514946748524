export const bscPools = [
  // {
  //   id: "prv-prv11",
  //   logo: "single-assets/PRV.png",
  //   name: "Kebab",
  //   token: "Kebab",
  //   tokenDescription: "PrivacySwap",
  //   tokenAddress: "0x7979f6c54eba05e18ded44c4f986f49a5de551c2",
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
  //   earnedToken: "gprv-prv1111",
  //   earnedTokenAddress: "0x08853D594D17909b0cbcb510058157C8571B6e94",
  //   earnContractAddress: "0x08853D594D17909b0cbcb510058157C8571B6e94",
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: "tokens",
  //   oracleId: "PRV",
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: "active",
  //   platform: "PrivacySwap",
  //   assets: ["PRV"],
  //   callFee: 0.5,
  //   buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
  //   addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  // },



  {
    id: "prv-prv",
    logo: "single-assets/PRV.png",
    name: "PRV",
    token: "PRV",
    tokenDescription: "PrivacySwap",
    tokenAddress: "0x7762A14082Ab475C06D3868B385E46aE27017231",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "gprv-prv",
    earnedTokenAddress: "0xaFd8B18676cA656218c5789dD53C2C06eEcd04f0",
    earnContractAddress: "0xaFd8B18676cA656218c5789dD53C2C06eEcd04f0",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "tokens",
    oracleId: "PRV",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PrivacySwap",
    assets: ["PRV","WBNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },



  {
    id: "ibg-ibg",
    logo: "ibg/ibg.png",
    name: "IBG",
    token: "IBG",
    tokenDescription: "iBGFinance",
    tokenAddress: "0x5c46c55A699A6359E451B2c99344138420c87261",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "ibg-prv",
    earnedTokenAddress: "0x6de071872a67D0D56073819d0806EB124d8342B1",
    earnContractAddress: "0x6de071872a67D0D56073819d0806EB124d8342B1",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "tokens",
    oracleId: "IBG",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "iBGFinance",
    assets: ["IBG","WBNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },


//==========================================


{id:"ibg-ibg-bnb",logo:"ibg/ibg-bnb.png",name:"IBG-BNB ",token:"IBG-BNB LP",tokenAddress:"0x8673491B4B72695D4BE789fe814D1dd0988e19A8",
earnedToken:"ibg-ibg-bnb",
tokenDescription:"IBG Finance",
tokenDecimals:18
,tokenDescriptionUrl:"https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake-bnb-lp",
earnedTokenAddress:"0x2372C93D56C18833c5768B54DADC2b226ee863AA",
earnContractAddress:"0x2372C93D56C18833c5768B54DADC2b226ee863AA",
pricePerFullShare:1,tvl:0,oracle:"lps",oracleId:"ibg-ibg-bnb",
oraclePrice:0,
depositsPaused:false,
status:"active",
platform:"IBG Finance ( Deposit Fee )",assets:["IBG","BNB"],callFee:.5,
addLiquidityUrl:"https://exchange.pancakeswap.finance/#/add/BNB/0x5c46c55A699A6359E451B2c99344138420c87261",
buyTokenUrl:"https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x5c46c55A699A6359E451B2c99344138420c87261"},



{
  id: "ibg-ibg-busd",
  logo: "ibg/ibg-busd.png",
  name: "IBG-BUSD ",
  token: "IBG-BUSD LP",
  tokenAddress:"0x36B8eE5c1f8FFB3b224736Ca9c989B1c531a613d",
  earnedToken:"ibg-ibg-busd",
  tokenDescription: "IBG Finance",
  tokenDecimals: 18,
  tokenDescriptionUrl:
    "https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake-bnb-lp",
  earnedTokenAddress: "0x6ffE55C42A27a95f24f9Ba57c6F6bEa541356221",
  earnContractAddress: "0x6ffE55C42A27a95f24f9Ba57c6F6bEa541356221",
  pricePerFullShare: 1,
  tvl: 0,
  oracle: "lps",
  oracleId: "ibg-ibg-busd",
  oraclePrice: 0,
  depositsPaused: false,
  status: "active",
  platform: "IBG Finance ( Deposit Fee )",
  assets: ["IBG", "BUSD"],
  callFee: 0.5,
  addLiquidityUrl:
    "https://exchange.pancakeswap.finance/#/add/BNB/0x5c46c55A699A6359E451B2c99344138420c87261",
  buyTokenUrl:
    "https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x5c46c55A699A6359E451B2c99344138420c87261",
},



 


  {
    id: '1bunny-bunny',
    logo: 'single-assets/BUNNY.png',
    name: 'BUNNY',
    token: 'BUNNY',
    tokenDescription: 'Pancakebunny',
    tokenAddress: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBunnyV2',
    earnedTokenAddress: '0xf539D6Fa66A596d82Dd9b10C6c6129041b4902b6',
    earnContractAddress: '0xf539D6Fa66A596d82Dd9b10C6c6129041b4902b6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUNNY',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Pancakebunny',
    assets: ['BUNNY'],
    callFee: 0.5,
    buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BUNNY',
  },

  //-9999999099898898989989890809



  {
    id: "rbh-rbh",
    logo: "single-assets/RBH.png",
    name: "Robinhood",
    token: "Robinhood",
    tokenDescription: "Robinhood Swap",
    tokenAddress: "0xD5779F2F9D7D239228e4e78bC78F50768661A081",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "gprv-RBH",
    earnedTokenAddress: "0x462e1770f7fEb271d8fFae207fE2b7cCa1125409",
    earnContractAddress: "0x462e1770f7fEb271d8fFae207fE2b7cCa1125409",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "tokens",
    oracleId: "RBH",
    oraclePrice: 0,
    depositsPaused: true ,
    status: "active",
    platform: "Robinhood",
    assets: ["RBH"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },

  {
    id: "cake-cake",
    logo: "single-assets/CAKE.png",
    name: "Cake",
    token: "Cake",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "gprv-cake",
    earnedTokenAddress: "0x138C51d539BB38CD7c7bf1E878A7Cfb9289e5ADa",
    earnContractAddress: "0x138C51d539BB38CD7c7bf1E878A7Cfb9289e5ADa",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "tokens",
    oracleId: "CAKE",
    oraclePrice: 0,
    depositsPaused: false ,
    status: "active",
    platform: "PancakeSwap",
    assets: ["CAKE"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.pancakeswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.pancakeswap.finance/#/add/BNB/",
  },


   
  {
    id: "banana-banana",
    logo: "single-assets/BANANA.svg",
    name: "BANANA",
    token: "BANANA",
    tokenDescription: "Apeswap",
    tokenAddress: "0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "gprv-banana",
    earnedTokenAddress: "0x713dc93d16D4AC94C2904847498fc7f666688f81",
    earnContractAddress: "0x713dc93d16D4AC94C2904847498fc7f666688f81",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "tokens",
    oracleId: "BANANA",
    oraclePrice: 0,
    depositsPaused: false ,
    status: "active",
    platform: "Apeswap",
    assets: ["BANANA"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.apeswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.apeswap.finance/#/add/BNB/",
  },
 
  {
    id: "kebab-kebab",
    logo: "single-assets/KEBAB.png",
    name: "Kebab Single",
    token: "Kebab Single",
    tokenDescription: "Kebab Finance",
    tokenAddress: "0x7979F6C54ebA05E18Ded44C4F986F49a5De551c2",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "gprv-kebab11",
    earnedTokenAddress: "0x936acB5226bd1a4319ebe444B14A900375cFE688",
    earnContractAddress: "0x936acB5226bd1a4319ebe444B14A900375cFE688",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "tokens",
    oracleId: "KEBAB",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Kebab Finance",
    assets: ["KEBAB"],
    callFee: 0.5,
    buyTokenUrl: "https://swap.kebabfinance.com/#/swap",
    addLiquidityUrl: "https://swap.kebabfinance.com/#/add/BNB/",
  },

 

  {
    id: "rbh-rbh-bnb",
    logo: "robinhood/rbh-bnb.png",
    name: "RBH-BNB ",
    token: "RBH-BNB LP",
    tokenAddress:"0x5b9f811D38fc6C1017E0774C552235b33F564DEb",
    earnedToken:"rbh-rbh-bnb",
    tokenDescription: "Robinhood",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake-bnb-lp",
    earnedTokenAddress: "0xCa6D60Ddec7eB24e561639655E124e9585b4B396",
    earnContractAddress: "0xCa6D60Ddec7eB24e561639655E124e9585b4B396",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "rbh-rbh-bnb",
    oraclePrice: 0,
    depositsPaused: true ,
    status: "active",
    platform: "Robinhood ( Deposit Fee )",
    assets: ["RBH", "BNB"],
    callFee: 0.5,
    addLiquidityUrl:
      "https://exchange.pancakeswap.finance/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    buyTokenUrl:
      "https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
  },
  {
    id: "rbh-rbh-busd",
    logo: "robinhood/rbh-busd.png",
    name: "RBH-BUSD",
    token: "RBH-BUSD LP",
    tokenAddress:"0x1F88F4C341365Bf67A96F4F01362EafC35e9dE1e",
    earnedToken:"rbh-rbh-busd",

    tokenDescription: "Robinhood",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake-bnb-lp",
    earnedTokenAddress: "0x94a503235EB50ac54ACd49118d87A4dDb77B8f3A",
    earnContractAddress: "0x94a503235EB50ac54ACd49118d87A4dDb77B8f3A",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "rbh-rbh-busd",
    oraclePrice: 0,
    depositsPaused: true ,
    status: "active",
    platform: "Robinhood",
    assets: ["RBH", "BUSD"],
    callFee: 0.5,
    addLiquidityUrl:
      "https://exchange.pancakeswap.finance/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    buyTokenUrl:
      "https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
  },
  {
    id: "rbh-prv-bnb",
    logo: "robinhood/prv-bnb.png",
    name: "PRV-BNB ( 3.5% Deposit Fee )",
    token: "PRV-BNB LP",
    tokenDescription: "Robinhood",
    tokenAddress:"0xCd18e1151a4831e5EbCEcC611369123012674253",
    tokenDecimals: 18,
    earnedToken:"rbh-prv-bnb",

    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake-bnb-lp",
    earnedTokenAddress: "0x13478FDAd424D1E07f4cb3d7947F4d65D6E7986E",
    earnContractAddress: "0x13478FDAd424D1E07f4cb3d7947F4d65D6E7986E",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "rbh-prv-bnb",
    oraclePrice: 0,
    depositsPaused: true,
    status: "active",
    platform: "Robinhood",
    assets: ["PRV", "BNB"],
    callFee: 0.5,
    addLiquidityUrl:
      "https://exchange.pancakeswap.finance/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    buyTokenUrl:
      "https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
  },
  {
    id: "rbh-prv-busd",
    logo: "robinhood/prv-busd.png",
    name: "PRV-BUSD ( 3.5% Deposit Fee )",

    token: "PRV-BUSD LP",
    tokenAddress:"0xea275C14414dE38f5C19C1e3559af032cc1facf4",
    earnedToken:"rbh-prv-busd",

    tokenDescription: "Robinhood",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake-bnb-lp",
    earnedTokenAddress: "0x424A9b0ec0fe485a8F8e35A2524E1BdA331E9F78",
    earnContractAddress: "0x424A9b0ec0fe485a8F8e35A2524E1BdA331E9F78",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "rbh-prv-busd",
    oraclePrice: 0,
    depositsPaused: true,
    status: "active",
    platform: "Robinhood",
    assets: ["PRV", "BUSD"],
    callFee: 0.5,
    addLiquidityUrl:
      "https://exchange.pancakeswap.finance/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    buyTokenUrl:
      "https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
  },


 
  {
    id: "kebab-btcb",
    logo: "kebab/kebab-btcb.png",
    name: "KEBAB-BTCB LP",
    token: "KEBAB-BTCB LP",
    tokenDescription: "Kebab Finance",
    tokenAddress: "0x45d8d5d9bd425efc26e3259848bcfefa16f927f0",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GPRV-KEBAB-BTCB LP",
    earnedTokenAddress: "0x1a460d5dd576ec779fA64d81295Ae19581C65876",
    earnContractAddress: "0x1a460d5dd576ec779fA64d81295Ae19581C65876",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "kebab-btcb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "Kebab Finance",
    assets: ["KEBAB", "BTCB"],
    callFee: 0.5,
    buyTokenUrl: "https://swap.kebabfinance.com/#/swap",
    addLiquidityUrl: "https://swap.kebabfinance.com/#/add/BNB/",
  },

 

 


 

  //==============

  {
    id: "cakev2-cake-bnb",
    logo: "cake-pairs/cake-bnb.svg",
    name: "CAKE-BNB",
    token: "CAKE CAKE-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x0eD7e52944161450477ee417DE9Cd3a859b14fD0",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "PRV-CAKE-BNB",
    earnedTokenAddress: "0xC5c662E1037F9647117f08A73e878beB08041024",
    earnContractAddress: "0xC5c662E1037F9647117f08A73e878beB08041024",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-cake-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["CAKE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
  


  {
    id: "cakev2-bunny-bnb",
    logo: "cake-pairs/bunny-bnb.svg",
    name: "BUNNY-BNB",
    token: "CAKE BUNNY-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x5aFEf8567414F29f0f927A0F2787b188624c10E2",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-BUNNY-BNB",
    earnedTokenAddress: "0x3ef9Fa5C7EE38Ec77ea35fAF07412280496a16E8",
    earnContractAddress: "0x3ef9Fa5C7EE38Ec77ea35fAF07412280496a16E8",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-bunny-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["BUNNY", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
  {
    id: "cakev2-alice-bnb",
    logo: "cake-pairs/alice-bnb.svg",
    name: "ALICE-BNB",
    token: "CAKE ALICE-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0xcAD7019D6d84a3294b0494aEF02e73BD0f2572Eb",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-ALICE-BNB",
    earnedTokenAddress: "0x6978DBCfA9E6B3d05e8C44499b750552ca0857Bb",
    earnContractAddress: "0x6978DBCfA9E6B3d05e8C44499b750552ca0857Bb",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-alice-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["ALICE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
 
 
  {
    id: "cakev2-reef-bnb",
    logo: "cake-pairs/reef-bnb.svg",
    name: "REEF-BNB",
    token: "CAKE REEF-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0xd63b5CecB1f40d626307B92706Df357709D05827",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-REEF-BNB",
    earnedTokenAddress: "0xd53BAcf274e55Af038AD4A77E8C4C77e917628E3",
    earnContractAddress: "0xd53BAcf274e55Af038AD4A77E8C4C77e917628E3",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-reef-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["REEF", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
  {
    id: "cakev2-uni-bnb",
    logo: "cake-pairs/uni-bnb.svg",
    name: "UNI-BNB",
    token: "CAKE UNI-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x014608E87AF97a054C9a49f81E1473076D51d9a3",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-UNI-BNB",
    earnedTokenAddress: "0x53aa6df8fBC2743e11Af6689d7a6f1d502324ea4",
    earnContractAddress: "0x53aa6df8fBC2743e11Af6689d7a6f1d502324ea4",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-uni-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["UNI", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
  {
    id: "cakev2-xrp-bnb",
    logo: "cake-pairs/xrp-bnb.svg",
    name: "XRP-BNB",
    token: "CAKE XRP-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-XRP-BNB",
    earnedTokenAddress: "0x3bB89b51fbFb8814646A6e1601F052E1F163EF3A",
    earnContractAddress: "0x3bB89b51fbFb8814646A6e1601F052E1F163EF3A",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-xrp-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["XRP", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
 
    
 
  {
    id: "cakev2-ada-bnb",
    logo: "cake-pairs/ada-bnb.svg",
    name: "ADA-BNB",
    token: "Cake ADA-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x28415ff2C35b65B9E5c7de82126b4015ab9d031F",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-ADA-BNB",
    earnedTokenAddress: "0x11d5ce7020Afd99f29f437f4e42a2595b65d6Bd8",
    earnContractAddress: "0x11d5ce7020Afd99f29f437f4e42a2595b65d6Bd8",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-ada-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["ADA", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
  {
    id: "cakev2-trx-bnb",
    logo: "cake-pairs/trx-bnb.svg",
    name: "TRX-BNB",
    token: "Cake TRX-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x3cd338c3BB249B6b3C55799F85a589FEbBBFf9Dd",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-TRX-BNB",
    earnedTokenAddress: "0xD720Fc0889D818A4DC51d16CBE309224CDb98781",
    earnContractAddress: "0xD720Fc0889D818A4DC51d16CBE309224CDb98781",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-trx-bnb",
    oraclePrice: 0,
    depositsPaused: true,
    status: "active",
    platform: "PancakeSwap",
    assets: ["TRX", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
  {
    id: "cakev2-zil-bnb",
    logo: "cake-pairs/zil-bnb.svg",
    name: "ZIL-BNB",
    token: "Cake ZIL-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x6A97867a4b7Eb7646ffB1F359ad582e9903aa1C2",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-ZIL-BNB",
    earnedTokenAddress: "0x433ee4E3F434c2bb85DE184D28A2C9A8faD77f76",
    earnContractAddress: "0x433ee4E3F434c2bb85DE184D28A2C9A8faD77f76",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-zil-bnb",
    oraclePrice: 0,
    depositsPaused: true,
    status: "active",
    platform: "PancakeSwap",
    assets: ["ZIL", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },

 
  {
    id: "cakev2-doge-bnb",
    logo: "cake-pairs/doge-bnb.svg",
    name: "DOGE-BNB",
    token: "Cake DOGE-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0xac109C8025F272414fd9e2faA805a583708A017f",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-DOGE-BNB",
    earnedTokenAddress: "0xD4Df975cF69f388beAe669aB2ad451F3Be55b239",
    earnContractAddress: "0xD4Df975cF69f388beAe669aB2ad451F3Be55b239",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-doge-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["DOGE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
  {
    id: "cakev2-bmxx-bnb",
    logo: "cake-pairs/bmxx-bnb.svg",
    name: "BMXX-BNB",
    token: "BMXX-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0xc20A92a1424b29b78DFaF92FD35D4cf8A06419B4",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-BMXX-BNB",
    earnedTokenAddress: "0x9b30eaf715ED5E1421D856dF8E0b7eD248866c1d",
    earnContractAddress: "0x9b30eaf715ED5E1421D856dF8E0b7eD248866c1d",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-bmxx-bnb",
    oraclePrice: 0,
    depositsPaused: true,
    status: "active",
    platform: "PancakeSwap",
    assets: ["BMXX", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
 
  {
    id: "cakev2-xvs-bnb",
    logo: "cake-pairs/xvs-bnb.svg",
    name: "XVS-BNB",
    token: "XVS-BNB LP",
    tokenDescription: "PancakeSwap",
    tokenAddress: "0x7EB5D86FD78f3852a3e0e064f2842d45a3dB6EA2",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "XGV-XVS-BNB",
    earnedTokenAddress: "0xd106cAcfc1Ff657AC9f76614c896545c224E503F",
    earnContractAddress: "0xd106cAcfc1Ff657AC9f76614c896545c224E503F",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "cakev2-xvs-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "PancakeSwap",
    assets: ["XVS", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://exchange.privacyswap.finance/#/swap",
    addLiquidityUrl: "https://exchange.privacyswap.finance/#/add/BNB/",
  },
  {
    id: "ape-banana-bnb",
    logo: "ape/banana-bnb.svg",
    name: "BANANA-BNB",
    token: "BANANA-BNB LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GPRV-BANANA-BNB",
    earnedTokenAddress: "0x59cFB56c4B8Cd755b6b2AC9797DD9eCc7A42D8a9",
    earnContractAddress: "0x59cFB56c4B8Cd755b6b2AC9797DD9eCc7A42D8a9",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-banana-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["BANANA", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },
  {
    id: "ape-banana-busd",
    logo: "ape/banana-busd.svg",
    name: "BANANA-BUSD",
    token: "BANANA-BUSD LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0x7Bd46f6Da97312AC2DBD1749f82E202764C0B914",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GPRV-BANANA-BUSD",
    earnedTokenAddress: "0xDAe403c30fe8A5c4Dbb42E2e2CeE572F6cA0EdaB",
    earnContractAddress: "0xDAe403c30fe8A5c4Dbb42E2e2CeE572F6cA0EdaB",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-banana-busd",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["BANANA", "BUSD"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },
 
  {
    id: "ape-eth-bnb",
    logo: "ape/eth-bnb.svg",
    name: "ETH-BNB",
    token: "APE ETH-BNB LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0xA0C3Ef24414ED9C9B456740128d8E63D016A9e11",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GPRV-ETH-BNB",
    earnedTokenAddress: "0xcEF2Ac0C1c4cD2b1f6122F836D8D29aFCEECe010",
    earnContractAddress: "0xcEF2Ac0C1c4cD2b1f6122F836D8D29aFCEECe010",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-eth-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["ETH", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },
 
 
 
 
  {
    id: "ape-matic-bnb",
    logo: "ape/matic-bnb.svg",
    name: "MATIC-BNB",
    token: "MATIC-BNB LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0x29A4A3D77c010CE100A45831BF7e798f0f0B325D",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GPRV-MATIC-BNB",
    earnedTokenAddress: "0x1C6fC6dB7e05339f548fc056FD163A44C977d259",
    earnContractAddress: "0x1C6fC6dB7e05339f548fc056FD163A44C977d259",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-matic-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["MATIC", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },
 
  
  {
    id: "ape-doge-bnb",
    logo: "ape/doge-bnb.svg",
    name: "DOGE-BNB",
    token: "DOGE-BNB LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0xfd1ef328A17A8e8Eeaf7e4Ea1ed8a108E1F2d096",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GPRV-DOGE-BNB",
    earnedTokenAddress: "0xFDb0f94DB4319205D3aDb14fA0916A632c12E6ad",
    earnContractAddress: "0xFDb0f94DB4319205D3aDb14fA0916A632c12E6ad",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-doge-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["DOGE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },
 
  {
    id: "ape-dot-bnb",
    logo: "ape/dot-bnb.svg",
    name: "DOT-BNB",
    token: "DOT-BNB LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0x21CBb561c5d7D70e9E6Cc42136CB22F07552aEef",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GPRV-DOT-BNB",
    earnedTokenAddress: "0xae69C9f1f0BB41FD0a62A1A677f76011E6f8E442",
    earnContractAddress: "0xae69C9f1f0BB41FD0a62A1A677f76011E6f8E442",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-dot-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["DOT", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },
 

  {
    id: "ape-ada-bnb",
    logo: "ape/ada-bnb.svg",
    name: "ADA-BNB",
    token: "ADA-BNB LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0x40d4543887E4170A1A40Cd8dB15A6b297c812Cb1",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GPRV-ADA-BNB",
    earnedTokenAddress: "0xb84E34B143DdE7062311760949802bE788658c44",
    earnContractAddress: "0xb84E34B143DdE7062311760949802bE788658c44",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-ada-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["ADA", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },
  {
    id: "ape-ada-eth",
    logo: "ape/ada-eth.svg",
    name: "ADA-ETH",
    token: "ADA-ETH LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0x61FE209E404166a53Cc627d0ae30A65606315dA7",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "GPRV-ADA-ETH",
    earnedTokenAddress: "0x519dc3568e51fDBB53B3B917c5d1da048CF10A8d",
    earnContractAddress: "0x519dc3568e51fDBB53B3B917c5d1da048CF10A8d",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-ada-eth",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["ADA", "ETH"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },
  
 



  {
    id: "ape-cake-bnb",
    logo: "ape/cake-bnb.svg",
    name: "CAKE-BNB",
    token: "CAKE-BNB LP",
    tokenDescription: "ApeSwap",
    tokenAddress: "0x60593Abea55e9Ea9d31c1b6473191cD2475a720D",
    tokenDecimals: 18,
    tokenDescriptionUrl:
      "https://docs.beefy.finance/beefyfinance/products/bifi-maxi",
    earnedToken: "APRV-CAKE-BNB",
    earnedTokenAddress: "0xd2C6e5837AeDa6AA6212Ba09321f8eE07053fF30",
    earnContractAddress: "0xd2C6e5837AeDa6AA6212Ba09321f8eE07053fF30",
    pricePerFullShare: 1,
    tvl: 0,
    oracle: "lps",
    oracleId: "ape-cake-bnb",
    oraclePrice: 0,
    depositsPaused: false,
    status: "active",
    platform: "ApeSwap",
    assets: ["CAKE", "BNB"],
    callFee: 0.5,
    buyTokenUrl: "https://dex.apeswap.finance/#/swap",
    addLiquidityUrl: "https://dex.apeswap.finance/#/add/BNB/",
  },
 
  

];
