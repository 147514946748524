import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import { Icon } from 'react-icons-kit';
import { home } from 'react-icons-kit/fa/home';
import { exchange } from 'react-icons-kit/fa/exchange';
import { arrowDownB } from 'react-icons-kit/ionicons/arrowDownB';
import { androidArrowDropup } from 'react-icons-kit/ionicons/androidArrowDropup';
import { androidMoreHorizontal } from 'react-icons-kit/ionicons/androidMoreHorizontal';
import { barChart } from 'react-icons-kit/fa/barChart'

import { shield } from 'react-icons-kit/fa/shield';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function FarmIcon(props) {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 0V7.26C1.2 7.9 0 9.6 0 11.5C0 14 2 16 4.5 16C6.79 16 8.71 14.28 8.97 12H13.17C13.06 12.32 13 12.66 13 13C13 13.7956 13.3161 14.5587 13.8787 15.1213C14.4413 15.6839 15.2044 16 16 16C16.7956 16 17.5587 15.6839 18.1213 15.1213C18.6839 14.5587 19 13.7956 19 13C19 12.66 18.94 12.32 18.82 12H20V9C20 7.89 19.11 7 18 7H13.04L11.65 0H3ZM5 2H10L11 7V10H8.74C8.45508 9.20575 7.95398 8.50686 7.29318 7.98211C6.63238 7.45735 5.83815 7.1276 5 7.03V2ZM4.5 9.25C5.09674 9.25 5.66903 9.48705 6.09099 9.90901C6.51295 10.331 6.75 10.9033 6.75 11.5C6.75 12.0967 6.51295 12.669 6.09099 13.091C5.66903 13.5129 5.09674 13.75 4.5 13.75C3.90326 13.75 3.33097 13.5129 2.90901 13.091C2.48705 12.669 2.25 12.0967 2.25 11.5C2.25 10.9033 2.48705 10.331 2.90901 9.90901C3.33097 9.48705 3.90326 9.25 4.5 9.25ZM16 11.5C16.3978 11.5 16.7794 11.658 17.0607 11.9393C17.342 12.2206 17.5 12.6022 17.5 13C17.5 13.3978 17.342 13.7794 17.0607 14.0607C16.7794 14.342 16.3978 14.5 16 14.5C15.6022 14.5 15.2206 14.342 14.9393 14.0607C14.658 13.7794 14.5 13.3978 14.5 13C14.5 12.6022 14.658 12.2206 14.9393 11.9393C15.2206 11.658 15.6022 11.5 16 11.5Z" fill="white" />
    </svg>

  )
}



function PoolIcon(props) {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 6L17.371 7.749C17.751 7.9 18 8.269 18 8.677V18C18 18.552 17.552 19 17 19H1C0.448 19 0 18.552 0 18V8.677C0 8.268 0.249 7.9 0.629 7.749L5 6H13ZM17 11H5V16H17V11ZM13 0C13.552 0 14 0.448 14 1V5H4V1C4 0.448 4.448 0 5 0H13Z" fill="white" />
    </svg>

  )
}


function PrvCard(props){
  return <svg {...props} viewBox="0 0 24 24" width="24px" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-bdfBwQ fafEdI"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.3137 3L15.2631 3.94934C14.5597 5.19866 14.7398 6.81097 15.8032 7.87441C16.8667 8.93786 18.479 9.11792 19.7283 8.4146L20.6777 9.36396L17.6569 12.3848L17.1287 11.8566C16.7382 11.4661 16.105 11.4661 15.7145 11.8566C15.3239 12.2471 15.3239 12.8803 15.7145 13.2708L16.2426 13.799L9.36396 20.6777L8.49923 19.8129C9.71921 18.5286 9.69924 16.4983 8.43932 15.2384C7.1794 13.9784 5.14908 13.9585 3.86473 15.1784L3 14.3137L9.87868 7.43503L10.2145 7.77081C10.605 8.16134 11.2382 8.16134 11.6287 7.77081C12.0192 7.38029 12.0192 6.74713 11.6287 6.3566L11.2929 6.02081L14.3137 3ZM12.8995 1.58579C13.6805 0.804738 14.9469 0.804738 15.7279 1.58579L17.299 3.15684C17.6895 3.54736 17.6895 4.18053 17.299 4.57105L17.2175 4.65257C16.7183 5.15173 16.7183 5.96103 17.2175 6.4602C17.7166 6.95936 18.5259 6.95936 19.0251 6.4602L19.1066 6.37868C19.4971 5.98816 20.1303 5.98816 20.5208 6.37868L22.0919 7.94975C22.8729 8.7308 22.8729 9.99713 22.0919 10.7782L10.7782 22.0919C9.99713 22.8729 8.7308 22.8729 7.94975 22.0919L6.37869 20.5208C5.98817 20.1303 5.98817 19.4971 6.37869 19.1066L7.02511 18.4602C7.52427 17.961 7.52427 17.1517 7.02511 16.6526C6.52594 16.1534 5.71664 16.1534 5.21748 16.6526L4.57106 17.299C4.18054 17.6895 3.54737 17.6895 3.15685 17.299L1.58579 15.7279C0.804738 14.9469 0.804738 13.6805 1.58579 12.8995L12.8995 1.58579ZM13.8787 8.6066C13.4882 8.21608 12.855 8.21608 12.4645 8.6066C12.0739 8.99712 12.0739 9.63029 12.4645 10.0208L13.4645 11.0208C13.855 11.4113 14.4882 11.4113 14.8787 11.0208C15.2692 10.6303 15.2692 9.99713 14.8787 9.6066L13.8787 8.6066Z"></path></svg>
}






function HomeIcon(props) {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 19C19 19.2653 18.8946 19.5196 18.7071 19.7071C18.5196 19.8947 18.2652 20 18 20H4C3.73478 20 3.48043 19.8947 3.29289 19.7071C3.10536 19.5196 3 19.2653 3 19V10H0L10.327 0.612042C10.5111 0.44452 10.7511 0.351685 11 0.351685C11.2489 0.351685 11.4889 0.44452 11.673 0.612042L22 10H19V19Z" fill="white" />
    </svg>

  )
}


function TradeIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 7H6V9H15L10 4V7ZM5 11L10 16V13H14V11H5Z" fill="white" />
    </svg>

  )
}

function PriceChart(props) {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10H4V19H0V10ZM14 6H18V19H14V6ZM7 0H11V19H7V0Z" fill="white" />
    </svg>


  )
}


function AuditIcon(props) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875ZM10.178 5.176C9.88313 5.3092 9.62498 5.51195 9.42569 5.76686C9.22641 6.02176 9.09195 6.32121 9.03384 6.63951C8.97574 6.95781 8.99573 7.28546 9.09209 7.59433C9.18846 7.90321 9.35832 8.18409 9.58712 8.41288C9.81591 8.64168 10.0968 8.81154 10.4057 8.90791C10.7145 9.00427 11.0422 9.02426 11.3605 8.96616C11.6788 8.90805 11.9782 8.77359 12.2331 8.57431C12.4881 8.37502 12.6908 8.11687 12.824 7.822C13.0868 8.67562 13.0563 9.59265 12.7375 10.427C12.4186 11.2613 11.8297 11.9648 11.0646 12.4256C10.2994 12.8864 9.40208 13.0778 8.51552 12.9694C7.62896 12.8609 6.8042 12.4589 6.17264 11.8274C5.54108 11.1958 5.13906 10.371 5.03064 9.48448C4.92223 8.59792 5.11365 7.70058 5.5744 6.93544C6.03516 6.1703 6.73875 5.58138 7.57305 5.26252C8.40735 4.94366 9.32438 4.9132 10.178 5.176Z" fill="white" />
    </svg>

  )
}

function HowToIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 0V20H2V16H0V14H2V11H0V9H2V6H0V4H2V0H6ZM18.005 0C19.107 0 20 0.898 20 1.99V18.01C20 19.109 19.107 20 18.005 20H8V0H18.005Z" fill="white" />
    </svg>

  )
}

function MoreIcon(props) {
  return (
    <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM16 0C14.9 0 14 0.9 14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0ZM9 0C7.9 0 7 0.9 7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0Z" fill="white" />
    </svg>

  )
}

function Valuts(props) {
  return (
    <svg viewBox="-21 -21 682.66669 682" fill="#FFFF" width="24px" color="text" xmlns="http://www.w3.org/2000/svg" class="sc-bdfBwQ fafEdI"><path d="m629.609375 303.507812-81.988281-40.820312-180.261719 89.753906c-14.582031 7.261719-30.957031 11.101563-47.359375 11.101563s-32.777344-3.839844-47.359375-11.101563l-180.261719-89.753906-81.988281 40.820312c-6.367187 3.171876-10.390625 9.671876-10.390625 16.785157 0 7.117187 4.023438 13.613281 10.390625 16.785156l278.964844 138.898437c9.59375 4.78125 20.117187 7.167969 30.644531 7.167969 10.523438 0 21.050781-2.386719 30.644531-7.160156l278.964844-138.90625c6.367187-3.171875 10.390625-9.667969 10.390625-16.785156 0-7.113281-4.023438-13.613281-10.390625-16.785157zm0 0"></path><path d="m629.609375 460.65625-81.988281-40.824219-180.261719 89.757813c-14.582031 7.261718-30.957031 11.09375-47.359375 11.09375s-32.777344-3.832032-47.359375-11.09375l-180.261719-89.757813-81.988281 40.824219c-6.367187 3.167969-10.390625 9.671875-10.390625 16.78125 0 7.113281 4.023438 13.613281 10.390625 16.789062l278.964844 138.898438c9.59375 4.777344 20.117187 7.167969 30.644531 7.167969 10.523438-.003907 21.050781-2.390625 30.644531-7.167969l278.964844-138.898438c6.367187-3.175781 10.390625-9.675781 10.390625-16.789062s-4.023438-13.613281-10.390625-16.78125zm0 0"></path><path d="m10.390625 179.972656 278.964844 138.902344c9.59375 4.773438 20.117187 7.167969 30.644531 7.167969s21.050781-2.394531 30.644531-7.167969l278.964844-138.902344c6.367187-3.167968 10.390625-9.671875 10.390625-16.78125 0-7.117187-4.023438-13.621094-10.390625-16.789062l-278.964844-138.90625c-19.191406-9.550782-42.097656-9.550782-61.289062 0l-278.964844 138.90625c-6.367187 3.167968-10.390625 9.671875-10.390625 16.789062 0 7.109375 4.023438 13.613282 10.390625 16.78125zm0 0"></path></svg>
  )
}

function ListItems(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);


  const handleClick2 = () => {
    setOpen2(!open2);
  }

  const handleClick4 = () => {
    setOpen4(!open4);
  }

  const handleClick3 = () => {
    setOpen3(!open3);
  }

  const handleClick = () => {
    setOpen(!open);
  };


  const handleNav = (url) => {
    window.open(url, '_blank').focus();
  }
  return (
    <div>
      <ListItem button onClick={() => {
        handleNav("https://app.privacyswap.finance")
      }}>
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: '#FFFFFF', padding: "10px 0px" }}
          >
            <HomeIcon />
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: '#FFFFFF' }}
          >
            <HomeIcon />
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText>
            <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>Home</span>
          </ListItemText>
        )}
      </ListItem>

      {
        <ListItem button onClick={handleClick}>
          {window.screen.width > 900 && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: '#FFFFFF', padding: "10px 0px" }}
            >
              <TradeIcon />
            </ListItemIcon>
          )}
          {window.screen.width < 900 && !props.closed && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: '#FFFFFF' }}
            >
              <TradeIcon />
              {/* <Icon icon={exchange} size={18} /> */}
            </ListItemIcon>
          )}
          {!props.closed && (
            <ListItemText>
              <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>Trade</span>
            </ListItemText>
          )}
          {!props.closed ? (
            open ? (
              <Icon icon={androidArrowDropup} style={{ color: '#FFFFFF' }} size={10} />
            ) : (
              <Icon icon={arrowDownB} size={10} style={{ color: '#FFFFFF' }} />
            )
          ) : (
            ''
          )}
        </ListItem>
      }
      {!props.closed && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={() => {
              handleNav("https://exchange.privacyswap.finance/#/swap")
            }}>
              <ListItemText

                style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Exchange{' '}
              </ListItemText>
            </ListItem>
            <ListItem button className={classes.nested}
              onClick={() => {
                handleNav("https://exchange.privacyswap.finance/#/pool")
              }}>
              <ListItemText style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Liquidty{' '}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      )}





      <ListItem button onClick={() => {
        handleNav("https://app.privacyswap.finance/farms")
      }}>
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ padding: "10px 0px", padding: "10px 0px" }}
          >
            <FarmIcon >
            </FarmIcon>
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}

          >
            <FarmIcon >
            </FarmIcon>
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText onClick={() => {
            console.log("clincccdd")
          }}>
            <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
              PrivacyFarms
            </span>
          </ListItemText>
        )}
      </ListItem>


      <ListItem button onClick={() => {
        handleNav("https://app.privacyswap.finance/pools")
      }}>
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: '#FFFFFF', padding: "10px 0px" }}
          >
            <PoolIcon fill="#FFFFFF">

            </PoolIcon>
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: '#FFFFFF' }}
          >
            <PoolIcon fill="#FFFFFF">

            </PoolIcon>
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText>
            <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
              PrivacyPools
            </span>
          </ListItemText>
        )}
      </ListItem>

      <ListItem button onClick={() => {
        handleNav("/")
      }}>
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: '#FFFFFF', padding: "10px 0px" }}
          >
            <Valuts fill="#FFFF" />
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: '#FFFFFF' }}
          >
            <Valuts fill="#FFFF" />
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText>
            <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
              PrivacyVaults
            </span>
          </ListItemText>
        )}
      </ListItem>





      <ListItem button onClick={() => {
        handleNav("https://app.privacyswap.finance/info")
      }}>
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: '#FFFFFF', padding: "10px 0px" }}
          >
            <PrvCard fill="#FFFF" />
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: '#FFFFFF' }}
          >
            <PrvCard fill="#FFFF" />
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText>
            <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
              Privacy Card
            </span>
          </ListItemText>
        )}
      </ListItem>






      {
        <ListItem button onClick={handleClick2}>
          {window.screen.width > 900 && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: '#FFFFFF', padding: "10px 0px" }}
            >
              <PriceChart />
            </ListItemIcon>
          )}
          {window.screen.width < 900 && !props.closed && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: '#FFFFFF' }}
            >
              <PriceChart />
            </ListItemIcon>
          )}
          {!props.closed && (
            <ListItemText>
              <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                Price Chart
              </span>
            </ListItemText>
          )}
          {!props.closed ? (
            open ? (
              <Icon icon={androidArrowDropup} style={{ color: '#FFFFFF' }} size={10} />
            ) : (
              <Icon icon={arrowDownB} size={10} style={{ color: '#FFFFFF' }} />
            )
          ) : (
            ''
          )}
        </ListItem>
      }



      {!props.closed && (
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={() => {
              handleNav("https://dex.guru/token/0x7762A14082Ab475C06D3868B385E46aE27017231-bsc")
            }}>
              <ListItemText style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Dex Guru{' '}
              </ListItemText>
            </ListItem>



            <ListItem button className={classes.nested} onClick={() => {
              handleNav("https://poocoin.app/tokens/0x7762A14082Ab475C06D3868B385E46aE27017231")
            }}>
              <ListItemText style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Poo Coin{' '}
              </ListItemText>
            </ListItem>



            <ListItem button className={classes.nested} onClick={() => {
              handleNav("https://charts.bogged.finance/?token=0x7762A14082Ab475C06D3868B385E46aE27017231")
            }}>
              <ListItemText style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Bogged Finance{' '}
              </ListItemText>
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => {
              handleNav("https://www.dextools.io/app/pancakeswap/pair-explorer/0xcd18e1151a4831e5ebcecc611369123012674253")
            }}>
              <ListItemText style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Dex Tools{' '}
              </ListItemText>
            </ListItem>






          </List>
        </Collapse>
      )}




      {
        <ListItem button onClick={handleClick3}>
          {window.screen.width > 900 && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: '#FFFFFF', padding: "10px 0px" }}
            >
              <AuditIcon />
            </ListItemIcon>
          )}
          {window.screen.width < 900 && !props.closed && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: '#FFFFFF' }}
            >
              <AuditIcon />
            </ListItemIcon>
          )}
          {!props.closed && (
            <ListItemText>
              <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>Audit</span>
            </ListItemText>
          )}
          {!props.closed ? (
            open ? (
              <Icon icon={androidArrowDropup} style={{ color: '#FFFFFF' }} size={10} />
            ) : (
              <Icon icon={arrowDownB} size={10} style={{ color: '#FFFFFF' }} />
            )
          ) : (
            ''
          )}
        </ListItem>
      }






      {!props.closed && (
        <Collapse in={open3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={() => {
              handleNav("https://github.com/PrivacySwap/Smart-Contract-Audits/blob/main/PrivacySwap.pdf")
            }}>
              <ListItemText style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Techrate Audit{' '}
              </ListItemText>
            </ListItem>



            <ListItem button className={classes.nested} onClick={() => {
              handleNav("https://www.certik.org/projects/privacyswap")
            }}>
              <ListItemText style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Certik Audit{' '}
              </ListItemText>
            </ListItem>


          </List>
        </Collapse>
      )}
















      {
        <ListItem button onClick={handleClick4}>
          {window.screen.width > 900 && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: '#FFFFFF',padding:"10px 0px" }}
            >
              <MoreIcon />
            </ListItemIcon>
          )}
          {window.screen.width < 900 && !props.closed && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: '#FFFFFF' }}
            >
              <MoreIcon />
            </ListItemIcon>
          )}
          {!props.closed && (
            <ListItemText>
              <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>More</span>
            </ListItemText>
          )}
          {!props.closed ? (
            open ? (
              <Icon icon={androidArrowDropup} style={{ color: '#FFFFFF' }} size={10} />
            ) : (
              <Icon icon={arrowDownB} size={10} style={{ color: '#FFFFFF' }} />
            )
          ) : (
            ''
          )}
        </ListItem>
      }
      {!props.closed && (
        <Collapse in={open4} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} onClick={() => {
              handleNav("https://github.com/privacyswap/")
            }}>
              <ListItemText style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Github{' '}
              </ListItemText>
            </ListItem>



            <ListItem button className={classes.nested} onClick={() => {
              handleNav("https://docs.privacyswap.finance/")
            }}>
              <ListItemText style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
                {' '}
                Docs{' '}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      )}





    </div>
  );
}

export default ListItems;
