import { govPoolABI } from '../abi';

export const bscStakePools = [
  {
    id: 'bifi-bnb',
    name: 'BIFI',
    logo: 'single-assets/BIFI.png',
    token: 'BIFI',
    tokenDecimals: 18,
    tokenAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'BNB',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    earnContractAddress: '0x453D4Ba9a2D594314DF88564248497F7D74d6b2C',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'WBNB',
    partnership: false,
    status: 'active',
    hideCountdown: true,
    partner: {
      logo: 'stake/beefy/beefyfinance.png',
      background: 'stake/beefy/background.png',
      text: "You probably already knew that Beefy is the most trusted Yield optimizer for the Binance Smart Chain. But did you know that Beefy has its own token? $BIFI has a maximum supply of 80000 tokens and there is no way to mint more. Everyone who holds our own $BIFI token can not only do cool stuff like create and vote on proposals, they also get a share of all harvests done, every hour, every day on all our +120 vaults. That's a lot of BNB that goes straight to our $BIFI holders. All you have to do is stake your $BIFI in this vault, it’s that simple, come back and harvest your BNB whenever you need it! (You can still vote on proposals even though you have staked your $BIFI here).",
      website: 'https://app.beefy.finance',
      social: {
        telegram: 'http://t.me/beefyfinance',
        twitter: 'https://twitter.com/beefyfinance',
      },
    },
  },
  {
    id: 'moo_bhc-hps2',
    name: 'Billionhappiness',
    logo: 'single-assets/HPS.png',
    token: 'mooBillionHPS',
    tokenDecimals: 18,
    tokenAddress: '0xAC8E64BE515fAe8c6026fb22954d4D1375938Bb5',
    tokenOracle: 'tokens',
    tokenOracleId: 'HPS',
    earnedToken: 'BHC',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
    earnContractAddress: '0x8e5fF8542Bd2c675F22C58ee146607df822F30B5',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BHC',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1621872008,
    partner: {
      logo: 'stake/billionhappiness/logo.png',
      background: 'stake/billionhappiness/background.png',
      text: 'BillionHappiness is a blockchain technology-based apparel company dedicated to providing happiness through a transparent record of information on its quality products. Our goal is to provide authenticity using blockchain technology to eliminate counterfeit items on the market. Billion Happiness has a token called BHC.',
      website: 'https://billionhappiness.finance',
      social: {
        telegram: 'https://t.me/BillionHappinessOfficial',
        twitter: 'https://twitter.com/BHC_Happiness',
      },
    },
  },
  {
    id: 'moo_belt_btc-iron',
    name: 'Iron Finance',
    logo: 'single-assets/BTCB.svg',
    token: 'mooBeltBTC',
    tokenDecimals: 18,
    tokenAddress: '0xD411121C948Cff739857513E1ADF25ED448623f8',
    tokenOracle: 'lps',
    tokenOracleId: 'belt-beltbtc',
    earnedToken: 'STEEL',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
    earnContractAddress: '0x57db966945691Ac03C704566BF5E20207def4215',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'STEEL',
    partnership: true,
    status: 'active',
    hideCountdown: false,
    isMooStaked: true,
    periodFinish: 1621620518,
    partner: {
      logo: 'stake/ironfinance/logo.png',
      background: 'stake/ironfinance/background.png',
      text: 'Inspired by FRAX, a unique fractionally-algorithmic stablecoin on the Ethereum network, and utilizing a similar approach, we have created IRON, the first partially-collateralized stablecoin on Binance Smart Chain. The IRON protocol makes use of 2 tokens to achieve its goal:  STEEL and IRON. STEEL - The share token of the Iron finance protocol. Serves as part of the collateral behind IRON. Backed by seigniorage revenue as well as the value of any excess collateral. IRON -  A stablecoin pegged to $1. Partially backed by a continuously adjusting ratio of collateral equal to $1 in value. Check out the docs for more information: https://docs.iron.finance/',
      website: 'https://app.iron.finance/',
      social: {
        telegram: 'https://t.me/ironfinance',
        twitter: 'https://twitter.com/IronFinance',
      },
    },
  },
  {
    id: 'moo_belt_4belt-tofy',
    name: 'MarshmallowDefi',
    logo: 'uncategorized/BELT-VENUSBLP.png',
    token: 'mooBelt4Belt',
    tokenDecimals: 18,
    tokenAddress: '0xc1fcf50ccaCd1583BD9d3b41657056878C94e592',
    tokenOracle: 'lps',
    tokenOracleId: 'belt-4belt',
    earnedToken: 'TOFY',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
    earnContractAddress: '0x42b9939020c2CeD30eA54B0A05D3aeD45DA74F54',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'TOFY',
    partnership: true,
    status: 'active',
    hideCountdown: false,
    isMooStaked: true,
    periodFinish: 1621440003,
    partner: {
      logo: 'stake/safefarm/logo.png',
      background: 'stake/safefarm/background.png',
      text: 'Toffie is the reference token for SAFEFARM within the MarshMallow ecosystem, it is the currency you receive in exchange when you pool / farm your Reflection Tokens.  Toffie is a Mintable token and will serve for multiple functions including the remuneration, purchase and exchange of NFTs on the platform within the Marshmallow Market-place.  The basic idea will be to create real NFTs made and developed by professionals in the sector, so as to bring to the community real valuables that can be exchanged and appreciated over time. All this mechanism can be managed by Toffie.',
      website: 'https://safefarms.marshmallowdefi.com/info',
      social: {
        telegram: 'https://t.me/MarshmallowDeFi',
        twitter: 'https://twitter.com/SwapMarshmallow',
      },
    },
  },
  {
    id: 'moo_belt_btc-xbtc',
    name: 'xBTC',
    logo: 'single-assets/BTCB.svg',
    token: 'mooBeltBTC',
    tokenDecimals: 18,
    tokenAddress: '0xD411121C948Cff739857513E1ADF25ED448623f8',
    tokenOracle: 'tokens',
    tokenOracleId: 'BTCB',
    earnedToken: 'BXBTC-BNB LP',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x8d112fcdf377a2c4cb41b60aae32199f939a866c',
    earnContractAddress: '0x1BA1B43227325E8Dc0FA1378d7C41fa7F49e32e0',
    earnContractAbi: govPoolABI,
    earnedOracle: 'lps',
    earnedOracleId: 'banana-bxbtc-bnb',
    partnership: true,
    status: 'closed',
    hideCountdown: false,
    isMooStaked: true,
    periodFinish: 1620928710,
    partner: {
      logo: 'stake/xbtc/logo.png',
      background: 'stake/xbtc/background.png',
      text: 'xBTC gives users one token access to every single digital asset on earth, pegged against Bitcoin dominance. We call this a “Dominance Hedge.” As the inferior and sluggish Bitcoin loses its dominance, xBTC holders will benefit. DeFi, Social Networks, Gaming, Smart Contracts – all blockchains and use cases are represented by xBTC. With a few clicks of the mouse, users benefit from access to the price action of hundreds of digital assets. This is a brand new type of hedge and asset – this is xBTC.\n',
      website: 'https://xbtc.fi/',
      social: {
        telegram: 'https://t.me/xBTC_Official',
        twitter: 'https://twitter.com/XBTC_Official',
      },
    },
  },
  {
    id: 'moo_belt_eth-icarus',
    name: 'Icarus Finance',
    logo: 'single-assets/ETH.png',
    token: 'mooBeltETH',
    tokenDecimals: 18,
    tokenAddress: '0xf2064C230b285AA6Cf45c6267DA86a8E3505D0AA',
    tokenOracle: 'tokens',
    tokenOracleId: 'ETH',
    earnedToken: 'ICA',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x95111f630ac215eb74599ed42c67e2c2790d69e2',
    earnContractAddress: '0xf6259516B5c38a110f634FcC2f14fEF02a318B66',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'ICA',
    partnership: true,
    status: 'closed',
    hideCountdown: false,
    isMooStaked: true,
    periodFinish: 1620836553,
    partner: {
      logo: 'stake/icarus/logo.png',
      background: 'stake/icarus/background.png',
      text: 'icarus.finance is a first of its kind decentralized mining protocol that brings Bitcoin and Ethereum hashrate to the Binance Smart Chain, combining it with further DeFi applications Do you want to mine, or farm crypto assets? Perhaps both? No problem! At icarus.finance, you choose"\n',
      website: 'http://icarus.finance',
      social: {
        telegram: 'https://t.me/icarus_finance',
        twitter: 'https://twitter.com/zetta_icarus',
      },
    },
  },
  {
    id: 'moo_belt_eth-satis',
    name: 'Satis Finance',
    logo: 'single-assets/ETH.png',
    token: 'mooBeltETH',
    tokenDecimals: 18,
    tokenAddress: '0xf2064C230b285AA6Cf45c6267DA86a8E3505D0AA',
    tokenOracle: 'tokens',
    tokenOracleId: 'ETH',
    earnedToken: 'SAT',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
    earnContractAddress: '0x36361230D435ce1829634cCd68Ba479628CaFe04',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'SAT',
    partnership: true,
    status: 'closed',
    hideCountdown: true,
    isMooStaked: true,
    periodFinish: 1520495415,
    partner: {
      logo: 'stake/satis/logo.png',
      background: 'stake/satis/background.png',
      text: '*Satis is an Automatic Deflationary Token, which means that there is a 2% burn on transactions, please keep this in mind when transferring and trading this token. (slippage 2,5%)* SatisFinance is a brand new DeFi project designed by an experienced development team as the 4th generation deflationary yield farm on Binance Smart Chain. Besides the buyback burning mechanism introduced by Goose Finance and Fullsail Finance, SatisFinance has implemented unique innovative features to fight against the inflation problem faced by most traditional yield farms.\n',
      website: 'https://satis.finance/',
      social: {
        telegram: 'https://t.me/satisfiChat',
        twitter: 'https://twitter.com/FinanceSatis',
      },
    },
  },
  {
    id: 'moo_bhc-hps',
    name: 'Billionhappiness',
    logo: 'single-assets/BHC.png',
    token: 'mooBHC',
    tokenDecimals: 18,
    tokenAddress: '0x56E899E56F6B8635B4A01b724db23674a64A7231',
    tokenOracle: 'tokens',
    tokenOracleId: 'BHC',
    earnedToken: 'BIFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    earnContractAddress: '0x79d9dd12f5c070eFbD9721F06dd8811825c9d9FC',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BIFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1620495415,
    partner: {
      logo: 'stake/billionhappiness/logo.png',
      background: 'stake/billionhappiness/background.png',
      text: 'BillionHappiness is a blockchain technology-based apparel company dedicated to providing happiness through a transparent record of information on its quality products. Our goal is to provide authenticity using blockchain technology to eliminate counterfeit items on the market. Billion Happiness has a token called BHC.',
      website: 'https://billionhappiness.finance',
      social: {
        telegram: 'https://t.me/BillionHappinessOfficial',
        twitter: 'https://twitter.com/BHC_Happiness',
      },
    },
  },

  {
    id: 'moo_auto_eth-apys',
    name: 'APYSwap',
    logo: 'single-assets/ETH.png',
    token: 'mooAutoETHv2',
    tokenDecimals: 18,
    tokenAddress: '0x0F47eaae1EC5fD11067455210c494a7828024524',
    tokenOracle: 'tokens',
    tokenOracleId: 'ETH',
    earnedToken: 'APYS-BNB LP',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xE5783Cc9dFb3E7e474B81B07369a008e80F1cEdb',
    earnContractAddress: '0x9A7DB018897B99F47661EcFFb143A7BF80724a7d',
    earnContractAbi: govPoolABI,
    earnedOracle: 'lps',
    earnedOracleId: 'cake-apys-bnb',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1619024404,
    partner: {
      logo: 'stake/apyswap/logo.png',
      background: 'stake/apyswap/background.png',
      text: 'Start Winning Today With APYSwap Staking. Staking is the process of holding tokens in a cryptocurrency wallet to support the operations of a network. Participants are rewarded for depositing and holding coins, with constant guaranteed time-based returns. Rewards are calculated based on staking time: the longer you stake, the more you earn.',
      website: 'https://apyswap.com/',
      social: {
        telegram: 'https://t.me/apyswapcom',
        twitter: 'https://twitter.com/apyswap',
      },
    },
  },

  {
    id: 'moo_belt_venus-mash',
    name: 'MarshmallowDeFi',
    logo: 'uncategorized/BELT-VENUSBLP.png',
    token: 'mooBeltVenusBLP',
    tokenDecimals: 18,
    tokenAddress: '0xb35Dc0b5eFd7c75590a9da55BE46d968c5804e24',
    tokenOracle: 'lps',
    tokenOracleId: 'belt-venus-blp',
    earnedToken: 'MASH',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x787732f27D18495494cea3792ed7946BbCFF8db2',
    earnContractAddress: '0x2Ad5e76e09ef581a8fb7B66901D80C75Db571824',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'MASH',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1619104315,
    partner: {
      logo: 'stake/mash/logo.png',
      background: 'stake/mash/background.png',
      text: 'MarshmallowDeFi (MASH) is the next generation of Automated Market Making (AMM) decentralized exchange with a deflationary governance token model. We are your go-to yield farm running on Binance Smart Chain and Pancakeswap exchange, with lots of other features that let you earn tokens. As with the current wave of second-generation yield farms, the aim is to create a perpetual deflation token, the MASH, with a continual burn mechanism in order to field an environment that can sustain long-term gains with consistently high APR for greater earnings.',
      website: 'https://marshmallowdefi.com/',
      social: {
        telegram: 'https://t.me/MarshmallowDeFi',
        twitter: 'https://twitter.com/SwapMarshmallow',
      },
    },
  },

  {
    id: 'moo_mdx_bnb-palm',
    name: 'YieldBay',
    logo: 'bnb-pairs/MDX-BNB.png',
    token: 'mooMdexMDX-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x31e2d52A04FEb223109506E4c6f195C0cF16b98A',
    tokenOracle: 'lps',
    tokenOracleId: 'mdex-bsc-mdx-bnb',
    earnedToken: 'PALM',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x9768E5b2d8e761905BC81Dfc554f9437A46CdCC6',
    earnContractAddress: '0x4B86435B0749b27008060Cb5696fceB606386835',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'PALM',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1618683181,
    partner: {
      logo: 'stake/yieldbay/logo.png',
      background: 'stake/yieldbay/background.png',
      text: 'Our mission is to create the most Economically Sustainable and Accessible yield farming community on BSC by providing a simple user interface, facilitating access to DeFi, and creating a perpetual deflation token, the PALM token. YieldBay has the goal of fostering AMM and DeFi market by facilitating the participation of traditional investors in the Crypto Ecosystem. Expanding the potential market reach requires simple and smooth interfaces as well as easier connections between Fiat and Crypto markets. To increase protocol economical sustainability, we aim at increasing burning fees and defining additional deflationary strategies benefitting holders.',
      website: 'https://yieldbay.finance/',
      social: {
        telegram: 'https://t.me/yieldbay',
        twitter: 'https://twitter.com/yieldbay',
      },
    },
  },

  {
    id: 'moo_auto_btc-typh',
    name: 'Typhoon',
    logo: 'single-assets/BTCB.svg',
    token: 'mooAutoBTCBv2',
    tokenDecimals: 18,
    tokenAddress: '0x0845813938515FE56730c124Ab8CA2DF58B01463',
    tokenOracle: 'tokens',
    tokenOracleId: 'BTCB',
    earnedToken: 'TYPH',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x4090e535F2e251F5F88518998B18b54d26B3b07c',
    earnContractAddress: '0x1643BC20913fA2D62C521E7cE8fFeD9e1Dd87964',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'TYPH',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1618853419,
    partner: {
      logo: 'stake/thypoon/logo.png',
      background: 'stake/thypoon/background.png',
      text: 'Typhoon is a decentralized, fully on-chain implemented project to enable private transactions between 2 wallets. It does this cleverly by somewhat taking on the role of proxy, but on cryptography steroids. Typhoon utilizes zkSNARK, a novel form of zero-knowledge cryptography. zkSNARK makes it possible for users to prove possession of information, without actually revealing that information. When depositing money into Typhoon, the user generates a random secret and submits a part of it (a hash) along with the assets into the smart contract. In order to then withdraw that deposit again, the user has to provide cryptographic proof that he is indeed the owner of a secret to an unspent deposit. All without revealing the secret he holds to the public blockchain, thanks to zkSNARK!',
      website: 'https://app.typhoon.network/',
      social: {
        telegram: 'https://t.me/typhoonnetwork',
        twitter: 'https://twitter.com/TyphoonCrypto',
      },
    },
  },

  {
    id: 'moo_bifi-biti',
    name: 'BitiCity',
    logo: 'single-assets/BIFI.png',
    token: 'mooBIFI',
    tokenDecimals: 18,
    tokenAddress: '0xf7069e41C57EcC5F122093811d8c75bdB5f7c14e',
    tokenOracle: 'tokens',
    tokenOracleId: 'BIFI',
    earnedToken: 'BITI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xA25Dab5B75aC0E0738E58E49734295baD43d73F1',
    earnContractAddress: '0xa8b86b9AF7e844DA90A2e72840Ad01CCBD11EdC3',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BITI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1618327543,
    partner: {
      logo: 'stake/biticity/logo.png',
      background: 'stake/biticity/background.png',
      text: 'Biti City is a NFT yield farm that combines the fun of gacha and leveling your NFT Bitibots to earn BITI token rewards. With the understanding that two is better than one, Bitibots are able to combine and breed newer generation Bitibots with the ability to earn BITI token (BITI) rewards via mining, sacrificing their parts in the process for the greater good. Each Bitibot’s unique set of DNA attributes determines their mining hashrate, and the overall success of Biti City. As a Bitibot master, the fate of the world now rests in your hands…',
      website: 'https://www.biti.city',
      social: {
        telegram: 'https://t.me/biti_city',
        twitter: 'https://twitter.com/bitibots',
      },
    },
  },

  {
    id: 'moo_belt_venus-palm',
    name: 'YieldBay',
    logo: 'uncategorized/BELT-VENUSBLP.png',
    token: 'mooBeltVenusBLP',
    tokenDecimals: 18,
    tokenAddress: '0xb35Dc0b5eFd7c75590a9da55BE46d968c5804e24',
    tokenOracle: 'lps',
    tokenOracleId: 'belt-venus-blp',
    earnedToken: 'PALM',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x9768E5b2d8e761905BC81Dfc554f9437A46CdCC6',
    earnContractAddress: '0xC1B4ed2861639ebFaFAeDb7eD2381052454bab43',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'PALM',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1618243760,
    partner: {
      logo: 'stake/yieldbay/logo.png',
      background: 'stake/yieldbay/background.png',
      text: 'Our mission is to create the most Economically Sustainable and Accessible yield farming community on BSC by providing a simple user interface, facilitating access to DeFi, and creating a perpetual deflation token, the PALM token. YieldBay has the goal of fostering AMM and DeFi market by facilitating the participation of traditional investors in the Crypto Ecosystem. Expanding the potential market reach requires simple and smooth interfaces as well as easier connections between Fiat and Crypto markets. To increase protocol economical sustainability, we aim at increasing burning fees and defining additional deflationary strategies benefitting holders.',
      website: 'https://yieldbay.finance/',
      social: {
        telegram: 'https://t.me/yieldbay',
        twitter: 'https://twitter.com/yieldbay',
      },
    },
  },

  {
    id: 'moo_cake_bnb-bingo',
    name: 'BingoCash',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    token: 'mooAutoCAKE-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x5DA77c7DBF6dBa1155CF343db06a7D79aaaad9Ee',
    tokenOracle: 'lps',
    tokenOracleId: 'auto-cake-bnb',
    earnedToken: 'sBGO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x53F39324Fbb209693332B87aA94D5519A1a49aB0',
    earnContractAddress: '0x253aABcC693aEE2180178174241857cBB08BEDD8',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'sBGO',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1618069191,
    partner: {
      logo: 'stake/bingo/logo.png',
      background: 'stake/bingo/background.png',
      text: 'Bingo Cash Finance is the AlgoStable coin protocol built on the Binance Smart Chain ("BSC")  for casino gaming. We are a fair launch project with no pre-sale, no investor, and no pre-mine. Ensuring that everyone is equal from the start. All AlgoStable currently available on BSC has almost no use case. Some have “games” yes but those don’t quite live up to help people release their stress. Many of them are just simple lottery or something very repetitive. On that premise, we took it upon ourselves to serve the good people of BSC with a whole lot of Casino games to quenches your thirst for entertainment.',
      website: 'https://bingocash.fi/',
      social: {
        telegram: 'https://t.me/bingocash_official',
        twitter: 'https://twitter.com/Bingocashfi',
      },
    },
  },

  {
    id: 'moo_auto_wbnb-thunder',
    name: 'ThunderSwap',
    logo: 'bnb-pairs/AUTO-BNB.png',
    token: 'mooAutoAUTO-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x6Cb6D451e7E7ca5d5F73715D6B5dfc55EfbC1f82',
    tokenOracle: 'lps',
    tokenOracleId: 'auto-auto-bnb',
    earnedToken: 'TNDR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
    earnContractAddress: '0x04715103e1d8A6D7a2B06737380DBd28a30Ca4Bc',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'TNDR',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1617984993,
    partner: {
      logo: 'stake/thunder/logo.png',
      background: 'stake/thunder/background.png',
      text: 'ThunderSwap.Finance — The new thundering Yield Farm on BSC Network (Binance Smart Chain). We, at ThunderSwap are a professional software team with lots of love towards crypto. We always wanted to contribute to the crypto community space and show our love for crypto. What else would be a better start than using our skills to build something new and exciting in the hot new DeFi space. Our goal is to contribute to crypto awareness and worldwide crypto acceptance. ThunderSwap is going to be our starting point for this journey. We are going to start small just with Thunder yield farms and pools, but we have a lot of exciting additions that are going to come live soon. ThunderSwap is going to use Thunder Token (TNDR) as the main fuel for the platform and we have a lot of plans to take it to the top charts with our roadmap.',
      website: 'https://thunderswap.finance/',
      social: {
        telegram: 'https://t.me/thunder_swap',
        twitter: 'https://twitter.com/thunder_swap',
      },
    },
  },

  {
    id: 'moo_auto_cake-swirl',
    name: 'Swirl Cash',
    logo: 'single-assets/CAKE.png',
    token: 'mooAutoCakeFixed',
    tokenDecimals: 18,
    tokenAddress: '0xe0B473c0dD6D7Fea5B395c3Ce7ffd4FEF0ab4373',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'SWIRL',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x52d86850bc8207b520340B7E39cDaF22561b9E56',
    earnContractAddress: '0x062939d2EAe7586424180E9b4D80e442885A6E2F',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'SWIRL',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1617725489,
    partner: {
      logo: 'stake/swirl/logo.png',
      background: 'stake/swirl/background.png',
      text: 'Swirl is a BSC-deployed fork of Tornado.cash, the strongest privacy protocol on the Ethereum network. It uses various cryptographic methods, including implementations of zero-knowledge(zkSnarks) proofs to achieve privacy functionality. So we’ve taken something that works, which allows 100M USD worth of tokens per day to regain their right to anonymity, customized it in Swirl for the current DeFi landscape, and brought it to Binance Smart Chain. Now, Swirl will allow you to send BSC cryptocurrency with 100% anonymity! *Swirl is a declinatory Token, which means that there is a 2% burn on transactions that get redistributed, please keep this in mind when transferring and trading this token.*',
      website: 'https://swirl.cash/',
      social: {
        telegram: 'https://t.me/Swirl_Cash',
        twitter: 'https://twitter.com/Swirl_Cash',
      },
    },
  },

  {
    id: 'moo_ellipsis_3pool-zefi',
    name: 'ZCore Finance',
    logo: 'uncategorized/EPS3.png',
    token: 'mooEllipsis3Pool',
    tokenDecimals: 18,
    tokenAddress: '0xE563c046147b4dF98bfCD3d00Dc54511F0c3b752',
    tokenOracle: 'lps',
    tokenOracleId: 'ellipsis-3eps',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    earnContractAddress: '0xc7ccd3520bEa91a87ecf39Ed39d9BD59946ED2b5',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1617555581,
    partner: {
      logo: 'stake/zcore/logo.png',
      background: 'stake/zcore/background.png',
      text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
      website: 'https://finance.zcore.network/',
      social: {
        telegram: 'https://t.me/ZCoreMiners',
        twitter: 'https://twitter.com/ZCoreCrypto',
      },
    },
  },

  {
    id: 'moo_1inch-hps',
    name: 'Billionhappiness',
    logo: 'single-assets/INCH.png',
    token: 'moo1INCH1INCH',
    tokenDecimals: 18,
    tokenAddress: '0xBa53AF4C2f1649F82e8070FB306DDBF2771A1950',
    tokenOracle: 'tokens',
    tokenOracleId: '1INCH',
    earnedToken: 'HPS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
    earnContractAddress: '0x9ae4496b063f5715561Cbe8f1d389a3FE4720258',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'HPS',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1617643560,
    partner: {
      logo: 'stake/billionhappiness/logo.png',
      background: 'stake/billionhappiness/background.png',
      text: 'BillionHappiness is a blockchain technology-based apparel company dedicated to providing happiness through a transparent record of information on its quality products. Our goal is to provide authenticity using blockchain technology to eliminate counterfeit items on the market. Billion Happiness has a token called BHC.',
      website: 'https://billionhappiness.finance',
      social: {
        telegram: 'https://t.me/BillionHappinessOfficial',
        twitter: 'https://twitter.com/BHC_Happiness',
      },
    },
  },

  {
    id: 'moo_auto_wbnb-naut',
    name: 'Astronaut',
    logo: 'single-assets/BNB.png',
    token: 'mooAutoWbnbFixed',
    tokenDecimals: 18,
    tokenAddress: '0x5B06aA1ebd2e15bC6001076355E5B4C39Cbc83F3',
    tokenOracle: 'tokens',
    tokenOracleId: 'WBNB',
    earnedToken: 'NAUT',
    earnedTokenDecimals: 8,
    earnedTokenAddress: '0x05B339B0A346bF01f851ddE47a5d485c34FE220c',
    earnContractAddress: '0x47F7CbE34aD6f857662759CDAECC48152237d135',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'NAUT',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1617388318,
    partner: {
      logo: 'stake/astronaut/logo.png',
      background: 'stake/astronaut/background.png',
      text: 'Astronaut is a protocol built for decentralizing the way in which new projects and ideas coming to the BSC ecosystem raise capital. With Astronaut, decentralized projects will be able to raise and exchange capital cheap and fast. Users will be able to participate in a secure and interoperable environment on the Binance Smart Chain. The deployment of the protocol will take place in III phases. During Phase I Astronaut is a deflationary token that burns and redistributes %s to its holders. During Phase II Astronaut will release its evolved platform and launchpad to provide secure, fair, and affordable launches to both projects and investors alike. Phase III will be the real game-changer with a bridge and a full DeFi ecosystem. Astronaut takes their initiative from the bottom up slowly increasing value to the project and holders of the native NAUT token. *Astronaut is an Automatic Yield Token, which means that there is a 4% burn on transactions that get redistributed, please keep this in mind when transferring and trading this token.*',
      website: 'https://astronaut.to/',
      social: {
        telegram: 'https://t.me/joinchat/pJTzEu-mhnAzMjMx',
        twitter: 'https://twitter.com/astronauttoken',
      },
    },
  },

  {
    id: 'moo_cake_bnb-space',
    name: 'Farm.Space',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    token: 'mooAutoCAKE-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x5DA77c7DBF6dBa1155CF343db06a7D79aaaad9Ee',
    tokenOracle: 'lps',
    tokenOracleId: 'auto-cake-bnb',
    earnedToken: 'SPACE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0abd3E3502c15ec252f90F64341cbA74a24fba06',
    earnContractAddress: '0x680dDCDAB13735d11a09d3c6d60867B2C75861bE',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'SPACE',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1617214423,
    partner: {
      logo: 'stake/space/logo.png',
      background: 'stake/space/background.png',
      text: 'Farm your way into DeFi space! Bringing dual farming rewards through margin trading and lending, to yield farming, on the Binance Smart Chain.',
      website: 'https://farm.space/',
      social: {
        telegram: 'https://t.me/farmdotspace',
        twitter: 'https://twitter.com/farmdotspace',
      },
    },
  },
  {
    id: 'moo_bifi_wbnb-nuts',
    name: 'Squirrel Finance',
    logo: 'bnb-pairs/BIFI-BNB.svg',
    token: 'mooPancakeBIFI-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x3B5332A476AbCdb80Cde6645e9e5563435e97772',
    tokenOracle: 'lps',
    tokenOracleId: 'cake-bifi-bnb',
    earnedToken: 'NUTS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
    earnContractAddress: '0x02e2B4212b8F5610E2ab548cB680cb58E61056F6',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'NUTS',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1616434156,
    partner: {
      logo: 'stake/squirrel/logo.png',
      background: 'stake/squirrel/background.png',
      text: 'In recent months the TVL (total value locked) inside BSC DeFi has skyrocketed due to an endless variety of token-incentivized adoption boosters known as Yield Farming. The problem introduced is some of these are experimental or unaudited projects, which can leave unsavvy farmers exposed if something were to go wrong. Because of the crazy APR % some of these can offer in the first few days, many users completely neglect to account for the possible risks introduced (Yolo-farming). Squirrel aims to resolve this problem by developing a trusted ecosystem to empower, secure & simplify DeFi for end users.',
      website: 'https://squirrel.finance/',
      social: {
        telegram: 'https://t.me/SquirrelDeFi',
        twitter: 'https://twitter.com/SquirrelDeFi',
      },
    },
  },
  {
    id: 'moo_venus_eth-ape',
    name: 'MEMEFARM',
    logo: 'stake/memefarm/ape.png',
    token: 'mooVenusETH',
    tokenDecimals: 18,
    tokenAddress: '0xA9936272065e6DDAc9D2453C9a2712B581e9aE1B',
    tokenOracle: 'tokens',
    tokenOracleId: 'ETH',
    earnedToken: 'APE',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xa9eA4b786ee5b7A733c035564Bfd9341A4c9FC1e',
    earnContractAddress: '0xEd1B64D539b945Fb291E5487F527D19B7748321e',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'APE',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1616260324,
    partner: {
      logo: 'stake/memefarm/logo.png',
      background: 'stake/memefarm/background.png',
      text: 'Meme Farm is an exciting protocol that combines DeFi yield-farming and rare non-fungible tokens (NFTs). You’re probably familiar with various food farming coin clones, most of which have a lifespan of 48 hours or less. While these tokens can make a small fortune for early adopters, most of them die out quickly because they lack actual utility. Most are simply governance tokens that get dumped by big farmers because they aren’t interested in participating in creating and voting on proposals. So what’s our solution for creating a high-yield farming token that can last in the long term? It’s to give the tokens actual utility and value (and thus, buy pressure) by combining them with one of the hottest cryptocurrency industries: NFTs.\n',
      website: 'https://memefarm.io/',
      social: {
        telegram: 'https://t.me/APEcoin_Chat',
        twitter: 'https://twitter.com/Go_MemeFarm',
      },
    },
  },
  {
    id: 'moo_belt_venus-slime',
    name: 'Slime Finance',
    logo: 'uncategorized/BELT-VENUSBLP.png',
    token: 'mooBeltVenusBLP',
    tokenDecimals: 18,
    tokenAddress: '0xb35Dc0b5eFd7c75590a9da55BE46d968c5804e24',
    tokenOracle: 'lps',
    tokenOracleId: 'belt-venus-blp',
    earnedToken: 'SLIME',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1',
    earnContractAddress: '0xba4Ee74E45De614bd8c2DFEEf16a4c13922C5659',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'SLIME',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1616087152,
    partner: {
      logo: 'stake/slimefinance/logo.png',
      background: 'stake/slimefinance/background.png',
      text: 'Slime Finance is a new yield farming project on the Binance Smart Chain. We yearn to offer competitive yield rates for our investors by delivering innovative updates in a timely manner. What differentiates us from other yield services is how we interact with our community. We strive to listen to all suggestions given on our social media and let the community vote for features. We are a team of software developers with bold ideas, working rigorously, passionately, and tirelessly on the Slime Finance project. We promise to deliver on the mid to long term a true utility to our Slime Token and fight against traditional yield farm inflation.',
      website: 'https://slime.finance/',
      social: {
        telegram: 'https://t.me/slimefinance',
        twitter: 'https://twitter.com/slimefinance',
      },
    },
  },
  {
    id: 'moo_venus_eth-brew',
    name: 'CafeSwap',
    logo: 'single-assets/ETH.png',
    token: 'mooVenusETH',
    tokenDecimals: 18,
    tokenAddress: '0xA9936272065e6DDAc9D2453C9a2712B581e9aE1B',
    tokenOracle: 'tokens',
    tokenOracleId: 'ETH',
    earnedToken: 'BREW',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x790Be81C3cA0e53974bE2688cDb954732C9862e1',
    earnContractAddress: '0xC7e3795259e9f74F4F2265Bf28680a70b41B4334',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BREW',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1615228996,
    partner: {
      logo: 'stake/cafeswap/cafeswap.png',
      background: 'stake/cafeswap/background.png',
      text: 'CafeSwap is a yield farming and staking platform on BSC Chain, We have chosen BSC because of its low tnx fees and faster speed. We have built this project to provide the best experience with farming while regulating the supply, we believe in partnerships hence we aim to bring all BSC DeFi ecosystems in one place to have a friendly ecosystem for all of us.',
      website: 'https://cafeswap.finance',
      social: {
        telegram: 'https://t.me/CafeSwap',
        twitter: 'https://twitter.com/cafeswapfinance',
      },
    },
  },
  {
    id: 'moo_venus_btc-ramen',
    name: 'RamenSwap',
    logo: 'single-assets/BTCB.svg',
    token: 'mooVenusBTC',
    tokenDecimals: 18,
    tokenAddress: '0x7255Ae13BA4484eD33fB48c4b833000dC12888B4',
    tokenOracle: 'tokens',
    tokenOracleId: 'BTCB',
    earnedToken: 'RAMEN',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
    earnContractAddress: '0x07613c90c6f4F4910e53A1A6fF5dAc352C6a16e4',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'Ramen',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1615229062,
    partner: {
      logo: 'stake/ramenswap/ramenswap.png',
      background: 'stake/ramenswap/background.png',
      text: 'The Most Delicious Yield Farm AMM on Binance Smart Chain. RamenSwap Finance is a community driven Yield Farming, DeFi Aggregator and DEX Aggregator on Binance Smart Chain. You can earn RAMEN by staking it on the pool and farming it by providing the liquidity to earn more delicious RAMEN. You can also maximize your return by compounding your token in the vault and connecting several DEX to find the most efficient swapping routes across all platforms that will be released in Q2 to support BSC space.\n',
      website: 'https://ramenswap.finance/',
      social: {
        telegram: 'https://t.me/ramenswap',
        twitter: 'https://twitter.com/ramenswap',
      },
    },
  },
  {
    id: 'moo_auto_cake-salt',
    name: 'SaltSwap',
    logo: 'single-assets/CAKE.png',
    token: 'mooAutoCAKEfixed',
    tokenDecimals: 18,
    tokenAddress: '0xe0B473c0dD6D7Fea5B395c3Ce7ffd4FEF0ab4373',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'SALT',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2849b1aE7E04A3D9Bc288673A92477CF63F28aF4',
    earnContractAddress: '0xDda39b0a11de6e0Ebce995D4A065960532EB332E',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'SALT',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1615229128,
    partner: {
      logo: 'stake/saltswap/saltswap.png',
      background: 'stake/saltswap/background.png',
      text: 'We are a team of experienced software developers and product managers from top tier tech companies (aka the salts) that want to contribute to the BSC DeFi yield farm movement and take it to the next level. Even though we are starting off with a salty yield farm, we will quickly move on to bigger and greater things. We have big plans for the SALT token.\n',
      website: 'https://saltswap.finance/',
      social: {
        telegram: 'https://t.me/saltswap',
        twitter: 'https://twitter.com/saltswap',
      },
    },
  },
  {
    id: 'moo_venus_ada-crow',
    name: 'CrowFinance',
    logo: 'single-assets/ADA.svg',
    token: 'mooVenusADA',
    tokenDecimals: 18,
    tokenAddress: '0x53c0CE2EBdA0c2A39327bE0B58a3c28A8Dcde420',
    tokenOracle: 'tokens',
    tokenOracleId: 'ADA',
    earnedToken: 'CROW',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xcc2E12a9b5b75360c6FBf23B584c275D52cDdb0E',
    earnContractAddress: '0x7fcfD0ceb5e9bD1A8b910b52983fe9c8aB656E20',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'CROW',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1615165746,
    partner: {
      logo: 'stake/crowfinance/crowfinance.png',
      background: 'stake/crowfinance/background.png',
      text: 'CrowFinance an Evolution Yield Farming on Binance Smart Chain with daytime and nighttime burn mechanism system fork from fry and add some function from thug + jetfuel',
      website: 'https://www.crowfinance.net/',
      social: {
        telegram: 'https://t.me/CrowFinance',
        twitter: 'https://twitter.com/crowfinance',
      },
    },
  },
  {
    id: 'moo_auto_wbnb-banana',
    name: 'ApeSwap',
    logo: 'single-assets/WBNB.svg',
    token: 'mooWBNB',
    tokenDecimals: 18,
    tokenAddress: '0x5B06aA1ebd2e15bC6001076355E5B4C39Cbc83F3',
    tokenOracle: 'tokens',
    tokenOracleId: 'WBNB',
    earnedToken: 'BANANA',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    earnContractAddress: '0xe4267bFDE62B79d27c8BeD68dB8C114ccBbEE545',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'BANANA',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1615489938,
    partner: {
      logo: 'stake/apeswap/logo.png',
      background: 'stake/apeswap/background.png',
      text: 'ApeSwap is an Automated Market Maker, Yield Farming, and Staking platform on Binance Smart Chain (forked from PancakeSwap). ApeSwap was built by DeFi Apes, for DeFi Apes. We have a dedicated team of experienced monkeys, who have been in the crypto space for years. $BANANA is the native currency of our platform. Stake, pool, and earn $BANANA all on ApeSwap.',
      website: 'https://apeswap.finance/',
      social: {
        telegram: 'https://t.me/ape_swap',
        twitter: 'https://twitter.com/ape_swap',
      },
    },
  },
  {
    id: 'moo_auto_beth-soups',
    name: 'Soup Protocol',
    logo: 'uncategorized/BETH-ETH.svg',
    token: 'mooAutoBETH-ETH',
    tokenDecimals: 18,
    tokenAddress: '0xF3C1EB01E40c47fd32D0397e56569809aae0e9c7',
    tokenOracle: 'lps',
    tokenOracleId: 'auto-beth-eth',
    earnedToken: 'SOUPS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x69F27E70E820197A6e495219D9aC34C8C6dA7EeE',
    earnContractAddress: '0x2526Bc61506665494E39cacCaF8c76A1f928D838',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'SOUPS',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1615489989,
    partner: {
      logo: 'stake/soup/logo.png',
      background: 'stake/soup/background.png',
      text: "Soup Protocol is an experimental project that enables the generation of SOUP tokens that runs on Binance Smart Chain. Inspired by the likes of Basis Cash & bDollar, SOUP is an algorithmic token that is designed to pegged its value to the Binance (BNB) token instead of a stablecoin. The protocol is designed to expand and contract the supply of SOUP tokens similar to the way central banks trade fiscal debt to stabilise purchasing power, without any rebases or collateral risk. The SOUP token can be used to buy and sell tokens on Pancake Swap and also can be used to interact with Soup Protocol's upcoming games such as Soup3D.",
      website: 'https://soups.finance/',
      social: {
        telegram: 'https://t.me/soup_community',
        twitter: 'https://twitter.com/soupingGood',
      },
    },
  },
];
