import { createMuiTheme } from '@material-ui/core/styles';

const createTheme = isNightMode =>
  createMuiTheme({
    palette: {
      type: isNightMode ? 'dark' : 'light',
      background: {
        default: '#00131F',
        paper: '#00131F',
        primary:  '#00131F',
        secondary: '#00131F',
        extra:  '#00131F',
        dark:  '#999',
        paused: "#00131f",
        retired:"#00131f",
        hover:  '#EFE6DC',
        border:  '#DED9D5',
      },
      primary: {
        main:  '#000',
      },
      secondary: {
        main: '#f3ba2f',
      },
      text: {
        primary: '#f3ba2f',
        secondary: '#FFFFFF',
      },
    },
    overrides: {
      MuiButton: {
        label: {
          color:  '#FFFFFF',
        },
      },
      // for dropdown menu items
      MuiButtonBase: {
        root: {
          color:  '#FFFFFF',
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color:  '#f3ba2f',
        },
        colorSecondary: {
          color: '#f3ba2f',
        },
      },
    },
  });

export default createTheme;
